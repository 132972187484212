'use strict';

require('./src/assets/scss/init.scss');
require("prism-themes/themes/prism-atom-dark.css");
require("prismjs/plugins/line-numbers/prism-line-numbers.css");
//exports.onClientEntry = () => {};

import Amplify, { Auth } from 'aws-amplify'
import awsConfig from './src/aws-exports'
Amplify.configure(awsConfig)


